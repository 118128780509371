import React from 'react';

export const EmailSentIcon = () => {
  return (
    <svg
      width="28px"
      height="24px"
      viewBox="0 0 92 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>18C771B0-E1D1-4079-ADD6-86B11CB3DD5A</title>
      <defs>
        <linearGradient
          x1="100.229675%"
          y1="49.9999853%"
          x2="-0.229589451%"
          y2="49.9999853%"
          id="linearGradient-1"
        >
          <stop stopColor="#41C7FF" offset="0%" />
          <stop stopColor="#8C84FF" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-0.229589451%"
          y1="49.9999853%"
          x2="100.229675%"
          y2="49.9999853%"
          id="linearGradient-2"
        >
          <stop stopColor="#41C7FF" offset="0%" />
          <stop stopColor="#8C84FF" offset="100%" />
        </linearGradient>
        <radialGradient
          cx="44.4802551%"
          cy="16.2699195%"
          fx="44.4802551%"
          fy="16.2699195%"
          r="45.3260206%"
          gradientTransform="translate(0.444803,0.162699),rotate(38.525447),scale(1.000000,2.330158),translate(-0.444803,-0.162699)"
          id="radialGradient-3"
        >
          <stop stopColor="#FFFFFF" stopOpacity="0.512046547" offset="0%" />
          <stop stopColor="#AFD4FE" stopOpacity="0.288488855" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="50%"
          cy="9.19869012%"
          fx="50%"
          fy="9.19869012%"
          r="63.5414968%"
          gradientTransform="translate(0.500000,0.091987),rotate(53.961390),scale(1.000000,0.957162),translate(-0.500000,-0.091987)"
          id="radialGradient-4"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%" />
        </radialGradient>
      </defs>
      <g id="Student+Educator" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="0.15-Email-sent" transform="translate(-832.000000, -220.000000)">
          <g id="box" transform="translate(792.000000, 196.000000)">
            <g id="email" transform="translate(40.000000, 24.000000)">
              <path
                d="M81.2303743,20 L42.3809524,23.5292217 L3.53153043,20 C1.58113748,20 0,21.5801038 0,23.5292217 L0,76.4707783 C0,78.4198962 1.58113748,80 3.53153043,80 L42.3809524,76.4707783 L81.2303743,80 C83.1807673,80 84.7619048,78.4198962 84.7619048,76.4707783 L84.7619048,23.5292217 C84.7619048,21.5801038 83.1807673,20 81.2303743,20 Z"
                id="Path"
                fill="url(#linearGradient-1)"
              />
              <path
                d="M83.6062925,73.8551807 L44.756965,38.5376664 C43.4099298,37.3128414 41.3521926,37.3128414 40.0051573,38.5376664 L1.1558299,73.8551807 C0.0765202907,74.8368514 -0.288207643,76.3811398 0.237543679,77.7424158 C0.763941473,79.1030452 2.07282268,80 3.53173375,80 L81.2303886,80 C82.6892997,80 83.9981809,79.1030452 84.523932,77.7424158 C85.05033,76.3811398 84.6856021,74.8368514 83.6062925,73.8551807 L83.6062925,73.8551807 Z"
                id="Path"
                fill="url(#linearGradient-2)"
              />
              <path
                d="M84.5887521,22.4343305 C84.1153812,20.9828965 82.7599366,20 81.2305346,20 L3.53174087,20 C2.00233894,20 0.646247675,20.9828965 0.173523387,22.4343305 C-0.29984754,23.8864098 0.217497736,25.4772434 1.45459959,26.3749513 L40.3039965,54.5711068 C41.5456251,55.4604247 43.2166504,55.4604247 44.458279,54.5711068 L83.3070293,26.3749513 C84.5441311,25.4765981 85.0614764,23.8864098 84.5887521,22.4343305 Z"
                id="Path"
                fill="#85DAFF"
                fillRule="nonzero"
              />
              <path
                d="M66.4280186,0.499999694 C73.3220053,0.507621992 79.5623802,3.30481436 84.0811639,7.82359804 C88.5999475,12.3423817 91.3971399,18.5827566 91.4047619,25.4761905 C91.4047619,32.3730734 88.6091538,38.6171199 84.0893273,43.1369464 C79.5695008,47.6567729 73.3254543,50.452381 66.4285714,50.452381 C59.5316885,50.452381 53.287642,47.6567729 48.7678155,43.1369464 C44.247989,38.6171199 41.452381,32.3730734 41.452381,25.4761905 C41.452381,18.5793076 44.247989,12.3352611 48.7678155,7.81543459 C53.287642,3.29560809 59.5316885,0.5 66.4280186,0.499999694 Z"
                id="Path"
                stroke="url(#radialGradient-4)"
                fill="url(#radialGradient-3)"
              />
              <path
                d="M73.012306,38.3574104 L64.4645976,38.3574104 L64.4645976,20.7862282 L58.2879921,24.8838863 L53.5473515,17.8075998 L66.3685229,9.30157146 C67.6792809,8.43173414 69.3656594,8.3507466 70.7546719,9.09053656 C72.1444668,9.83110541 73.012306,11.2717491 73.012306,12.8401042 L73.012306,38.3574104 Z"
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
